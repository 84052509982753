/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@import url('https://fonts.googleapis.com/css?family=Roboto');

/* @font-face {
  font-family: 'Roboto';
  src: url('/public/Roboto-Medium.ttf') format('truetype'),
} */

body {
  font-family: 'Roboto';
}

html {
  display: block;
}

*, body {
  margin: 0;
}

* {
  padding: 0;
  box-sizing: border-box;
}

div {
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.home_container {
  background-color: #ebc908;
  background-image: none;
  background-size: cover;
  background-position: center center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home_background {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: url(/public/bg.png);
  background-size: cover;
  background-position: center center;
  padding: 24px;
  background-color: #ebc908;
}

.home_logo {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.logo_image {
  width: 300px;
  transition: height 0.5s ease 0s;
}

.home_row {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  padding: 24px;
}

@media only screen and (min-width: 767px) {
  .home_row {
    flex-direction: row;
  }
  .card_count {
    margin-top: -20px;
  }
  .gif_one {
    width: 300px;
  }
}

.row_gif {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: none;
  background-size: cover;
  background-position: center center;
}

.gif_one {
  box-shadow: rgb(0 0 0 / 70%) 0px 5px 11px 2px;
  border: 4px dashed #ff1dec;
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  transition: width 0.5s ease 0s;
}

.row_separator {
  height: 32px;
  width: 32px;
}

.row_card {
  display: flex;
  flex: 2 1 0%;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: none;
  background-size: cover;
  background-position: center center;
  background-color: #505050;
  padding: 24px;
  border-radius: 24px;
  border: 4px dashed #ff1dec;
  box-shadow: rgb(0 0 0 / 70%) 0px 5px;
}

.card_count {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: #FFF;
  line-height: 1.6;
}

.card_address {
  text-align: center;
  color: #FFF;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.address_link {
  color: #ff1dec;
  text-decoration: none;
}

.card_buttons {
  text-align: center;
  margin: 10px;
}

.card_button {
  border-radius: 50px;
  border: none;
  background-color: #ff1dec;
  padding: 10px;
  margin: 5px;
  font-weight: bold;
  color: #FFF;
  width: 100px;
  cursor: pointer;
  box-shadow: rgb(250 250 250 / 30%) 0px;
  text-decoration: none;
}

.card_cost {
  text-align: center;
  color: #FFF;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
}

.card_separator {
  height: 8px;
  width: 8px;
}

.card_text {
  text-align: center;
  color: #FFF;
  font-size: 16px;
  line-height: 1.6;
}

.card_counters {
  display: flex;
  flex: 0 1 0%;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: none;
  background-size: cover;
  background-position: center center;
  margin: 10px;
}

.card_connect {
  display: flex;
  flex: 0 1 0%;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: none;
  background-size: cover;
  background-position: center center;
}

.connect_text {
  text-align: center;
  color: #FFF;
  font-size: 16px;
  line-height: 2.6;
}

.connect_button {
  border-radius: 50px;
  border: none;
  background-color: #ff1dec;
  padding: 10px;
  font-weight: bold;
  color: #FFF;
  width: 100px;
  cursor: pointer;
  box-shadow: rgb(250 250 250 / 30%) 0px;
}

.counters_button {
  border-radius: 100%;
  border: none;
  background-color: #ebc908;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: #FFF;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgb(250 250 250 / 30%) 0px;
}

.counters_number {
  text-align: center;
  color: #FFF;
  font-size: 16px;
  line-height: 1.6;
}

.card_buy {
  display: flex;
  flex: 0 1 0%;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: none;
  background-size: cover;
  background-position: center center;
}

.buy_button {
  border-radius: 50px;
  border: none;
  background-color: #ff1dec;
  padding: 10px;
  font-weight: bold;
  color: #ffffff;
  width: 100px;
  cursor: pointer;
  box-shadow: rgb(250 250 250 / 30%) 0px;
}

.home_footer {
  display: flex;
  flex: 0 1 0%;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  background-image: none;
  background-size: cover;
  background-position: center center;
  width: 70%;
}

.footer_text {
  text-align: center;
  color: #FFF;
  font-size: 16px;
  line-height: 1.6;
}

.footer_separator {
  height: 16px;
  width: 16px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
